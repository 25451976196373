// --------------------------------------------------------------------

// Default HTML Overrides

// - Button
.button-base {
    border: none !important;
    margin: none;
    outline: none;
    border-radius: 0;
    //
    appearance: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;

    font-family: inherit;
    font-size: inherit;
    text-transform: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: none;

    align-content: center;
    align-items: center;
    justify-content: center;

    overflow: visible;
    width: max-content;
    max-width: 100%;

    svg {
        display: inline-flex;
    }

    &::-moz-focus-inner {
        border: 0;
    }
}

// - Link
.link-base {
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: var(--utmb-color-default) !important;
        text-decoration: none;
        opacity: 0.95;
    }

    &:hover {
        opacity: 1;
    }
}

.-stretched-link {
    &::before {
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: auto;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
}

// - List
.list-reset {
    list-style: none;
}

// --------------------------------------------------------------------

// Classes for component styling defaults

.component-base {
    margin-top: rem(100px);
    margin-bottom: rem(100px);
}

.font-heading-base {
    max-width: rem(378px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(15px);
    font-weight: 500;

    @include bp(md) {
        margin-bottom: rem(25px);
    }
}

.font-subheading-base,
.font-summary-base {
    max-width: rem(583px);
    margin-left: auto;
    margin-right: auto;
    line-height: 180%;
}

.font-summary-base {
    max-width: rem(376px);
    font-weight: 500;
}

// --------------------------------------------------------------------

// Container Utility Class for Mobile Padding

.mob_pad {
    padding: 0 2rem !important;

    @include bp(md) {
        padding: 0 !important;
    }
}

// --------------------------------------------------------------------

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
    // width: 1em;
    // height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
    fill: var(--utmb-color-default);
}

.svg-icon circle {
    stroke: var(--utmb-color-default);
    stroke-width: 1;
}

// --------------------------------------------------------------------

/* -----
Colors
----- */

.color-primary {
    color: var(--utmb-color-primary);
    fill: var(--utmb-color-primary);
}

.color-secondary {
    color: var(--utmb-color-secondary);
    fill: var(--utmb-color-secondary);
}

// --------------------------------------------------------------------

/* -----
Visibility
----- */

.hidden {
    display: none !important;
}

// --------------------------------------------------------------------
