@import '../../styles/common/common.scss';

.container {
    height: rem(129px);
    width: 100%;
    background-color: var(--utmb-color-red);
    color: var(--utmb-color-white);
    text-align: left;
}
@media (orientation: landscape) {
    .container {
        max-width: rem(375px);
    }
}

.slide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.container_picture {
    position: relative;
    width: rem(136px);
    height: 100%;
}
.card {
    padding-left: rem(10px);
}

.slide-item {
    position: relative;
    flex: 0 0 100% !important;
}
.slide-content {
    flex: 1;
    height: 100%;
    overflow: hidden;
}
.slide-content > *:first-child {
    width: rem(136px) !important;
}
.quiet {
    cursor: pointer;
    background-color: transparent;
    border: rem(1px) solid transparent;
    padding: rem(5px);
}

.prev,
.next {
    position: absolute;
    height: rem(30px);
}
.prev:focus,
.next:focus {
    outline: -webkit-focus-ring-color auto rem(4px) !important;
    box-shadow: 0 0 rem(1000px) rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 !important;
}

.prev {
    top: rem(10px);
    right: 14%;
}
.next {
    top: rem(10px);
    right: 5%;
}
@media (orientation: landscape) {
    .prev {
        top: auto;
        bottom: rem(2px);
        right: 14%;
    }
    .next {
        top: auto;
        bottom: rem(6px);
        right: 5%;
    }
}
