@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.7);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@-webkit-keyframes zoomInOut {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    20% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.7);
    }
}

@keyframes zoomInOut {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    20% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.7);
    }
}
