@import '../../styles/common/common.scss';

.dot {
    width: rem(8px);
    height: rem(8px);
    border-radius: 50%;
    background-color: var(--utmb-color-blue-light) !important;

    &:not(:last-of-type) {
        margin-right: rem(12px);
    }
    &.selected {
        background-color: var(--utmb-color-blue-dark) !important;
    }
}

.slider_next_btn,
.slider_previous_btn {
    @include focus-outline;
}

.slider_next_btn {
    transform: scale(0.8);
    cursor: pointer;
    border: none;
    top: rem(-40px);
    left: rem(-80px);

    @include bp(lg) {
        transform: scale(1);
        top: rem(-40px);
        left: rem(-40px);
    }
}

.slider_previous_btn {
    cursor: pointer;
    transform: rotate(180deg) scale(0.8);
    top: unset;
    bottom: rem(-40px);
    right: rem(-50px);

    @include bp(lg) {
        transform: rotate(180deg) scale(1);
        bottom: unset;
        top: rem(-40px);
        left: rem(-40px);
    }
}
