@import '../../styles/common/common.scss';

.container_slider {
    display: none;
    overflow: hidden;
    z-index: 5;
    @include bp(md) {
        display: block;
    }
}
.portrait {
    display: block;
    overflow: hidden;

    @include bp(md) {
        display: none;
    }
}
.container_baseOnly {
    height: auto !important;
    min-height: unset !important;
    max-height: unset !important;

    .race_CSS {
        display: none !important;
    }

    padding-bottom: rem(100px);

    @include bp(xxs) {
        padding-bottom: rem(115px);
    }

    @include bp(sm) {
        padding-bottom: rem(120px);
    }

    @include bp(md) {
        padding-bottom: rem(135px);
    }
}

.container {
    position: relative;
    width: 100vw;
    min-height: rem(550px);
    height: calc(calc(var(--vh, 1vh) * 100) - var(--mobile-bar-height));
    display: flex;
    align-items: center;
    justify-content: center;

    /* FOR BG BLEND */
    opacity: 0.99;
    background-color: rgba(204, 204, 204, 0.978);
    /* FOR BG BLEND */

    text-align: center;
    color: var(--utmb-color-white);
    overflow: hidden;
    text-shadow: var(--utmb-text-shadows);

    @include bp(md) {
        width: 100%;
        height: calc(100vh - var(--top-bar-height));
    }

    .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: -5 !important;

        &.filter {
            filter: var(--utmb-filter-bgimg) !important;
        }
    }

    // Mobile Logo
    .mobile_logo {
        align-items: center;
        justify-content: center;
        display: flex;
        height: rem(120px);
        margin-bottom: rem(70px);
        margin-top: calc(var(--partners-menu-container-height-mobile) + rem(10px));
        position: relative;
        width: rem(220px);
        z-index: 200;

        // iPhone X
        @include bp(sm) {
            width: rem(130px);
        }

        @include bp(md) {
            width: rem(200px);
        }

        @include bp(lg) {
            display: none !important;
        }
    }

    // Main Centered Content
    .page_header_pcenter {
        width: 100vw;
        z-index: 10;

        @include bp(md) {
            margin-bottom: rem(100px);
        }

        @include bp(lg) {
            margin-top: var(--menu-height);
        }

        // .spacers {
        //     height: rem(30px);
        // }

        .mobile_hidden {
            display: none;

            @include bp(md) {
                display: block;
            }
        }

        .ph_title {
            color: rgba(255, 255, 255, 0.75);
            margin: auto;
            width: 75%;

            @include bp(lg) {
                font-size: rem(60px);
                font-size: clamp(rem(60px), 7vw, rem(80px));
            }

            @include bp(xxl) {
                font-size: rem(80px);
                font-size: clamp(rem(80px), 5.5vw, rem(90px));
            }

            &_ws {
                color: var(--utmb-color-white);
                text-transform: uppercase;

                @include bp(lg) {
                    font-size: clamp(rem(40px), 7vw, rem(60px));
                }

                @include bp(xxl) {
                    font-size: clamp(rem(60px), 5.5vw, rem(80px));
                }
            }
        }

        .ph_summary {
            margin: 0 auto;
            font-size: rem(22px);
            font-weight: 400 !important;
            letter-spacing: 0.4px !important;
            line-height: rem(30px);
            color: rgba(255, 255, 255, 0.7);
            max-width: 90%;

            @include bp(lg) {
                max-width: rem(750px);
                line-height: clamp(rem(30px), 2.1vw, rem(35px));
            }

            &_ws {
                color: var(--utmb-color-white);
                text-transform: uppercase;

                @include bp(lg) {
                    font-size: rem(36px);
                    line-height: rem(44px);
                }
            }
        }

        .race_CSS {
            font-size: rem(12px);

            @include bp(lg) {
                font-size: rem(16px);
            }
        }

        .image_container {
            margin: auto;

            .image_wrapper {
                position: relative;
                width: rem(180px);
                height: rem(140px);
                margin: 0 auto;

                @include bp(md) {
                    width: rem(240px);
                    height: rem(200px);
                }

                @include bp(lg) {
                    width: rem(280px);
                    height: rem(220px);
                }

                @include bp(xl) {
                    width: rem(350px);
                    height: rem(300px);
                }
            }
        }
    }

    .v_triangle,
    .h_triangle {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        margin-left: auto;
        clip-path: polygon(100% 0%, 0% 100%, 100% 100%);

        @include bp(md) {
            display: block;
        }
    }

    .v_triangle {
        width: rem(240px);
        height: calc(100vh - (var(--menu-height) + rem(40px)));
        background-color: var(--utmb-color-primary);

        @include bp(xxl) {
            width: rem(375px);
        }
    }

    .h_triangle {
        width: 160vw;
        height: rem(170px);
        background-color: var(--utmb-color-secondary);

        @include bp(xxl) {
            height: rem(260px);
        }
    }

    .style_block {
        z-index: 1;

        .page_header_bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            position: relative;
            width: 100vw;
            min-height: rem(100px);
            bottom: rem(20px);

            .main_cta {
                display: inline-block;
                line-height: rem(28px);

                .external_link {
                    text-underline-offset: rem(7px);

                    span {
                        letter-spacing: 1.5px;
                        font-family: var(--utmb-font-futura-heavy);
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }

                .is_button {
                    text-decoration: none;
                }
            }

            .cta_scroll {
                margin-top: rem(10px);
                display: none;
            }
        }
    }
}

.container_subpage {
    max-height: unset;

    @include bp(sm) {
        justify-content: flex-start;
        padding-top: rem(60px);
        padding-bottom: rem(50px);
        min-height: unset;
        height: auto;
    }

    @include bp(lg) {
        padding-bottom: rem(50px);
    }
}

// Background Image

// Z-INDEXES

.social_share {
    z-index: 50;
    color: var(--utmb-color-black);

    &.is_dark {
        color: var(--utmb-color-white);
    }
}

.events_slider {
    z-index: 50;
}
