.absolute {
    position: absolute;
}

.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cover {
    width: 100% !important;
    height: 100% !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
