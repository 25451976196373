///
/// Generate `:focus` and `:hover` styles in one go.
///
/// @content Wrapped in `:focus` and `:hover` pseudo-classe.
/// @output  Wraps the given content in `:focus` and `:hover` pseudo-classe.
///
@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

///
/// Generate `:active`, `:focus` and `:hover` styles in one go.
///
/// @content Wrapped in `:focus` and `:hover` pseudo-classe.
/// @output  Wraps the given content in `:focus` and `:hover` pseudo-classe.
///
@mixin active-hover-focus {
    &:active,
    &:hover,
    &:focus {
        @content;
    }
}

///
/// Set outline to handle focus
///
/// @content Wrapped in `:focus` and `:hover` pseudo-classe.
/// @output  Wraps the given content in `:focus` and `:hover` pseudo-classe.
///
@mixin focus-outline {
    &:focus {
        outline: rem(3px) solid var(--utmb-color-primary);
        outline-offset: rem(-3px);
    }
}
